import { createClient } from '@sanity/client';

const projectId = process.env.GATSBY_SANITY_PROJECT_ID;
const envDataset = process.env.GATSBY_SANITY_DATASET;
const apiVersion = process.env.GATSBY_SANITY_API_VERSION;

export function getSanityPreviewClient (
  preview = {},
  previewDataset = envDataset,
) {
  const client = createClient({
    projectId,
    dataset: previewDataset,
    apiVersion,
    useCdn: false,
    token: preview.token
  })
  return client;
}