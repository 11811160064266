import clsx from 'clsx';
import { Formik } from 'formik';
import * as React from 'react';
import RichTextBlock from './richTextBlock';
import Button from './button';
import { encode } from '../utils/encode';
import HeadingBlock from './headingBlock';

export const FormInput = ({
  type,
  name,
  id,
  placeholder,
  value,
  className = "peer py-2.5 px-4 w-full min-w-0 appearance-none border-0 bg-[var(--color-scheme-input-background)] focus:bg-[var(--color-scheme-input-background-active)] text-[color:var(--color-scheme-input-foreground)] focus:text-[color:var(--color-scheme-input-foreground-active)] outline-none ring-2 ring-inset ring-[color:var(--color-scheme-input-border)] placeholder:text-[color:var(--color-scheme-input-placeholder)] focus:ring-2 focus:ring-inset focus:ring-[color:var(--color-scheme-input-border-active)]",
  ...props
}) => {
  return (
    <input type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      value={value}
      className={className}
      {...props} />
  )
}

export const FormText = ({
  name,
  id,
  rows = "12",
  placeholder,
  value,
  className = "peer py-2.5 px-4 w-full min-w-0 appearance-none border-0 bg-[var(--color-scheme-input-background)] focus:bg-[var(--color-scheme-input-background-active)] text-[color:var(--color-scheme-input-foreground)] focus:text-[color:var(--color-scheme-input-foreground-active)] outline-none ring-2 ring-inset ring-[color:var(--color-scheme-input-border)] placeholder:text-[color:var(--color-scheme-input-placeholder)] focus:ring-2 focus:ring-inset focus:ring-[color:var(--color-scheme-input-border-active)]",
  ...props
}) => {
  return <textarea name={name}
    id={id}
    rows={rows}
    value={value}
    className={className}
    placeholder={placeholder}
    {...props}></textarea>
}

export const FormField = ({
  fieldType,
  ...props
}) => {
  switch (fieldType) {
    case 'input':
      return <FormInput {...props} />
    case 'text':
      return <FormText {...props} />
    default:
      return <></>
  }
}

const DynamicFormField = ({
  error,
  touched,
  value,
  label,
  placeholder,
  fieldType,
  twoColumnWidth,
  threeColumnWidth,
  required,
  id,
  columns,
  ...props
}) => {
  const getFieldType = () => {
    switch (fieldType) {
      case 'multi':
        return {fieldType: 'text'}
      case 'single':
        return {fieldType: 'input', type: 'text'}
      default:
        return {fieldType: 'input', type: fieldType}
    }
  }

  const getColSpan = () => {
    switch (columns) {
      case "1":
        return 'md:col-span-1'
      case "2":
        switch (twoColumnWidth) {
          case "1":
            return 'md:col-span-1'
          case "2":
            return 'md:col-span-2'
          default:
            return 'md:col-span-1'
        }
      case 3:
        switch (threeColumnWidth) {
          case "1":
            return 'md:col-span-1'
          case "2":
            return 'md:col-span-2'
          case "3":
            return 'md:col-span-3'
          default:
            return 'md:col-span-1'
        }
      default:
        return 'md:col-span-1'
    }
  }

  return (
    <div className={clsx('space-y-2', getColSpan())}>
      {placeholder && label && <HeadingBlock value={label}
          headingType='h5' />}
      <div className='relative group'>
        <FormField {...getFieldType()} {...props} id={id.current} name={id.current} value={value} />
        {(placeholder || label) && <div className={clsx("overflow-hidden absolute left-0 top-0 px-4 py-2.5 w-full h-full flex pointer-events-none italic group-focus-within:opacity-0 transition-opacity text-[color:var(--color-scheme-input-placeholder)]", value.length ? 'opacity-0' : 'opacity-100')}>
          <label htmlFor={id.current}>{placeholder || label}</label>
        </div>}
      </div>
      {(error && touched) && (
        <div className="mt-1 text-[color:var(--color-scheme-foreground)]">
          {error}
        </div>
      )}
    </div>
  )
}

export const DynamicForm = ({
  fields,
  id,
  gridColumns,
  terms,
  buttonLabel,
  buttonColor,
  buttonPosition
}) => {
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [submitFail, setSubmitFail] = React.useState(false);

  const getInitialValues = React.useCallback(() => {
    const values = {};
    fields.forEach(i => {
      values[i.id.current] = '';
    })
    return values;
  }, [fields])

  const validateForm = React.useCallback((values) => {
    const errors = {};
    fields.forEach(i => {
      if(i.required && !values[i.id.current]) {
        errors[i.id.current] = 'Field is required.'
      }
      if(i.fieldType === 'email') {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[i.id.current])) {
          errors[i.id.current] = "Invalid email address";
        }
      }
    })
    return errors;
  }, [fields]);

  const onSubmit = React.useCallback((values, {setSubmitting, resetForm}) => {
    setTimeout(() => {
      setSubmitting(false);
      fetch("", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": id,
          ...values,
        }),
      })
        .then((response) => {
          setSubmitSuccess(true);
          resetForm(getInitialValues());
        })
        .catch((error) => {
          console.error(error);
          setSubmitFail(true);
        })
    }, 400);
  }, [getInitialValues, fields, id])

  const getGridColumns = () => {
    switch (gridColumns) {
      case "1":
        return 'grid-cols-1'
      case "2":
        return 'grid-cols-1 md:grid-cols-2'
      case "3":
        return 'grid-cols-1 md:grid-cols-3'
      default:
        return 'md:grid-cols-1'
    }
  }

  const getButtonPosition = () => {
    switch (buttonPosition) {
      case 'left':
        return 'justify-start';
      case 'center':
        return 'justify-center';
      case 'right':
        return 'justify-end';
      case 'inline':
        return 'justify-end flex-1';
      default:
        break;
    }
  }

  return (
    <Formik initialValues={getInitialValues()}
      validate={validateForm}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <>
          <form name={id}
            data-netlify="true"
            onSubmit={handleSubmit}
            className={clsx('space-y-12', submitSuccess && "hidden")}
            netlify-honeypot="bot-field">
            <div className="hidden">
              <label>
                Don't fill this out if you're human:{" "}
                <input name="bot-field" />
              </label>
            </div>
            <div className={clsx('grid gap-6 items-start justify-start', getGridColumns())}>
              {fields.map((i,k) => (
                <DynamicFormField {...i}
                  value={values[i.id.current]}
                  error={errors[i.id.current]}
                  touched={touched[i.id.current]}
                  onChange={handleChange}
                  columns={gridColumns}
                  key={k} />
              ))}
            </div>
            <div className={clsx('flex gap-x-8 gap-y-4', buttonPosition === 'inline' ? 'md:flex-row flex-col items-center' : 'flex-col')}>
              <div>
                <RichTextBlock content={terms}/>
              </div>
              <div className={clsx('flex whitespace-nowrap', getButtonPosition())}>
                <Button label={buttonLabel}
                  buttonColor={buttonColor}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit" />
              </div>
            </div>
          </form>
          <div className={clsx("h-full flex items-center", !submitSuccess && "hidden")}>
            <p className="text-lg font-medium mb-4 text-center text-[color:var(--color-scheme-foreground)]">
              Your submission has been received. A member of our team will be in touch as soon as possible.
            </p>
          </div>
          <div className={clsx(!submitFail && "hidden")}>
            <p className="text-lg font-medium mb-4 mt-2 text-center text-[color:var(--color-scheme-foreground)]">
              Something went wrong and your message was not sent. Please try again or contact us.
            </p>
          </div>
        </>
      )}
    </Formik>
  )
}