import * as React from 'react';
import Link from './link';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import SocialLinksList from './socialLinksList';
import Image from './Image';
import RichTextBlock from './richTextBlock';
import Signup from './signup';

const entityName = 'Heaps Good Studios';

const Footer = ({
  footerBackgroundClass = "color-scheme-four"
}) => {
  const footerSettings = useStaticQuery(graphql`
    query {
      sanitySocialLinks {
        facebook
        github
        instagram
        linkedin
        pinterest
        snapchat
        threads
        tiktok
        twitter
        vimeo
        youtube
      }
      sanityFooter {
        footerLogos {
          image {
            src: asset {
              gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 600)
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 600)
              publicURL: url
              extension
            }
            alt: caption
          }
          customWidth
          width
          colorScheme
        }
        title
        placeholder
        submit
        menuTitle
        menuLinks {
          url
          label
          newWindow
          noFollow
        }
        contactTitle
        contactContent: _rawContactContent(resolveReferences: {maxDepth: 10})
      }
    }
  `)

  const {
    sanitySocialLinks,
    sanityFooter: {
      footerLogos,
      menuTitle,
      menuLinks,
      contactTitle,
      contactContent,
      title,
      placeholder,
      submit
    }
  } = footerSettings;

  const socialLinks = Object.entries(sanitySocialLinks || []).map(i => ({
    type: i[0],
    url: i[1]
  }))
    .filter(i => i.url);

  const FooterLogo = ({
    colorReference = footerBackgroundClass,
    className = 'max-w-[120px]'
  }) => {
    const filterLogos = footerLogos.filter(i => i.colorScheme === colorReference);
    const logoSettings = filterLogos.length > 0 ? filterLogos[0] : footerLogos[0];
    const {
      customWidth,
      width,
      image
    } = logoSettings;
    const props = {
      style: {},
      className: [className]
    };
    if(width === 'full') {
      props.layout = 'fullWidth';
      props.className.push('w-full');
    }
    else if(width === 'custom') {
      props.width = customWidth;
      props.style.width = `${customWidth}px`;
    }
    return <Image src={image.src}
      alt={image.alt}
      className={clsx(props.className)}
      {...props} />
  }

  return (
    <footer className={clsx("bg-[var(--color-scheme-background)] pt-8 md:pt-16 pb-16 space-y-4", footerBackgroundClass)}
      aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <div className='contain space-y-12'>
        <div className='grid md:grid-cols-2 gap-x-6 gap-y-12 lg:grid-cols-4'>
          <div className="flex flex-col justify-center items-start gap-y-4 gap-x-8">
            <Link to="/">
                <FooterLogo />
            </Link>
          </div>
          {menuLinks.length > 0 &&
            <div>
              {menuTitle && <h5 className="font-medium text-xl mb-3 md:mb-4 text-[color:var(--color-scheme-foreground)]">{menuTitle}</h5>}
              <ul className="flex flex-col gap-y-2">
                {menuLinks.map((i,k) => (
                  <li className="relative"
                    key={k}>
                    <Link className="text-[color:var(--color-scheme-link)] hover:text-[color:var(--color-scheme-link-active)] transition"
                      to={i.url}>
                      {i.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>}
          {contactContent.length > 0 && <div>
            {contactTitle && <h5 className="font-medium text-xl mb-3 md:mb-4 text-[color:var(--color-scheme-foreground)]">{contactTitle}</h5>}
            <RichTextBlock content={contactContent} />  
          </div>}
          <div>
            {title && <h5 className="font-medium text-xl mb-3 md:mb-4 text-[color:var(--color-scheme-foreground)]">{title}</h5>}
            <Signup submit={submit} placeholder={placeholder} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-x-8 gap-y-8">
          <div className='flex flex-row gap-x-4'>
            <SocialLinksList links={socialLinks}/>
          </div>
          <div className='flex flex-col md:items-center md:flex-row gap-y-2 gap-x-6 text-sm'>
            <Link to='/privacy'
              className='underline text-[color:var(--color-scheme-link)] hover:text-[color:var(--color-scheme-link-active)]'>Privacy Policy</Link>
            <p className='text-[color:var(--color-scheme-accent-one-bg)]'>&copy; {new Date().getFullYear()} {entityName}.</p>
          </div>
        </div>
      </div>

      

    </footer>
  )
}

export default Footer;