import * as React from 'react';
import { PreviewContext } from '../../../context/PreviewProvider';
import { getSanityPreviewClient } from '../../utils/sanity';
import {LiveQueryProvider} from "@sanity/preview-kit";
import NotFoundPage from '../../pages/404';
import PreviewBanner from './previewBanner';
import PreviewLayout from './previewLayout';

export default function SanityPreviewConnectorProvider({children}) {
  const {isPreview, documentId} = React.useContext(PreviewContext);
  React.useEffect(() => {
    async function getSanityUserData() {
      await getSanityPreviewClient()
        .config({withCredentials: true})
        .users.getById('me');
    }
    getSanityUserData();
  }, []);

  if(!isPreview) {
    return children;
  }

  if(!documentId) {
    return <NotFoundPage />
  }

  const client = getSanityPreviewClient({
    token: process.env.GATSBY_SANITY_KEY
  });

  return (
    <>
      <PreviewBanner />
      <LiveQueryProvider client={client}>
        <PreviewLayout>
          {children}
        </PreviewLayout>
      </LiveQueryProvider>
    </>
  )
}