import './src/styles/global.css'
import * as React from 'react';
import SanityPreviewConnectorProvider from './src/components/preview/sanityPreviewConnector';
import PreviewProvider from './context/PreviewProvider';

export const wrapRootElement = ({element}) => {
  return (
    <PreviewProvider>
      <SanityPreviewConnectorProvider>
        {element}
      </SanityPreviewConnectorProvider>
    </PreviewProvider>
  )
}