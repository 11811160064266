import { Formik } from "formik";
import * as React from "react";
import clsx from "clsx";
import { encode } from "../utils/encode";
import { FormField } from "./form";
import Button from "./button";

const validateForm = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Invalid email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const initialValues = {
  email: ""
};

const Signup = ({
  placeholder,
  submit
}) => {
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [submitFail, setSubmitFail] = React.useState(false);

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setTimeout(() => {
      setSubmitting(false);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "signup",
          ...values,
        }),
      })
        .then((response) => {
          setSubmitSuccess(true);
          resetForm(initialValues);
        })
        .catch((error) => {
          console.error(error);
          setSubmitFail(true);
        });
    }, 400);
  };

  return (
    <Formik initialValues={initialValues}
      validate={validateForm}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <>
          <form className={clsx("grid gap-y-1", submitSuccess && "hidden")}
            name="signup"
            data-netlify="true"
            onSubmit={handleSubmit}
            netlify-honeypot="bot-field">
            <div className="hidden">
              <label>Don't fill this out if you're human:{" "}
                <input name="bot-field" />
              </label>
            </div>
            <div>
              <FormField placeholder={placeholder}
                fieldType='input'
                id="subscribe-email"
                autoComplete="email"
                type="email"
                name="email"
                className="w-full min-w-0 !border-0 !border-b !py-2 !px-0 outline-0 leading-4 bg-transparent autofill:!bg-transparent border-[color:var(--color-scheme-input-border)] text-[color:var(--color-scheme-input-foreground)] placeholder:text-[color:var(--color-scheme-input-placeholder)]"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.email && touched.email ? errors.email : null} />
            </div>
            {(errors.email && touched.email) && (
              <div className="text-[color:var(--color-scheme-foreground)] italic text-sm">
                {errors.email}
              </div>
            )}
            <div className="flex justify-start mt-3">
              <Button label={submit}
                isLoading={isSubmitting}
                disabled={isSubmitting}
                type="submit" />
            </div>
          </form>
          <div className={clsx("h-full flex items-center", !submitSuccess && "hidden")}>
            <p className="text-lg font-medium mb-4 text-center">
              Thanks for subscribing!
            </p>
          </div>
          <div className={clsx(!submitFail && "hidden")}>
            <p className="text-lg font-medium mb-4 mt-2 text-center">
              Something went wrong. Please try again or contact us.
            </p>
          </div>
        </>
      )}
    </Formik>
  );
}

export default Signup;