import * as React from 'react';
import Link from './link';
import clsx from 'clsx';

const Button = ({label, url, className, buttonColor, isLoading, ...props}) => {

  const colorClasses = buttonColor === 'buttonTwo' ? 'bg-[var(--color-scheme-button-two-background)] text-[color:var(--color-scheme-button-two-foreground)] border-[color:var(--color-scheme-button-two-border)] hover:text-[color:var(--color-scheme-button-two-foreground-active)] hover:border-[color:var(--color-scheme-button-two-border-active)]' : 'bg-[var(--color-scheme-button-one-background)] text-[color:var(--color-scheme-button-one-foreground)] border-[color:var(--color-scheme-button-one-border)] hover:text-[color:var(--color-scheme-button-one-foreground-active)] hover:border-[color:var(--color-scheme-button-one-border-active)]';

  const buttonClassName = clsx('group relative w-full md:w-auto px-16 pt-3 pb-3 flex items-center justify-center transition-transform font-medium leading-4 border-2 group relative overflow-hidden disabled:opacity-40', colorClasses, className);

  const ButtonContents = () => {
    return (
      <>
        <div className={clsx("absolute w-full h-full -translate-x-full group-hover:translate-x-0 transition-transform", buttonColor === 'buttonTwo' ? "bg-[var(--color-scheme-button-two-background-active)]": "bg-[var(--color-scheme-button-one-background-active)]")}></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={clsx("absolute animate-spinner h-5 w-5", !isLoading && "hidden")}
          viewBox="0 0 50 50">
          <circle
            className={clsx("animate-dash", buttonColor === 'buttonTwo' ? "stroke-[color:var(--color-scheme-button-two-foreground)] group-hover:stroke-[color:var(--color-scheme-button-two-foreground-active)]" : "stroke-[color:var(--color-scheme-button-one-foreground)] group-hover:stroke-[color:var(--color-scheme-button-one-foreground-active)]")}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
            style={{ strokeLinecap: "round" }}
          ></circle>
        </svg>
        <span className={clsx("relative", isLoading && "opacity-0", buttonColor === 'buttonTwo' ? "text-[color:var(--color-scheme-button-two-foreground)] group-hover:text-[color:var(--color-scheme-button-two-foreground-active)]" : "text-[color:var(--color-scheme-button-one-foreground)] group-hover:text-[color:var(--color-scheme-button-one-foreground-active)]")}>
          {label}
        </span>
      </>
    )
  }

  if (url) {
    return (
      <Link className={buttonClassName}
        to={url}
        {...props}>
          <ButtonContents />
      </Link>
    )
  }

  return (
    <button className={clsx(buttonClassName, 'cursor-pointer')}
      {...props}>
        <ButtonContents />
    </button>
  )
}

export default Button;