import * as React from 'react';

const FacebookIcon = ({
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.5 20.3"
      className={className}
      aria-hidden="true"
      fill="inherit"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1"><path d="M10.5,3.3V0H6.9A4.14,4.14,0,0,0,3.2,3.7V7.2H0V11H3.1v9.3H6.8V10.9h3.3l.4-3.7H6.9V4.5A1.3,1.3,0,0,1,8.1,3.2,22.75,22.75,0,0,0,10.5,3.3Z" /></g>
      </g>
    </svg>
  )
}

export default FacebookIcon;