import * as React from 'react';
import Navbar from './navigation';
import Footer from './footer';

const Layout = ({
  location, 
  children, 
  navBackgroundClass, 
  asideColorScheme,
  footerBackgroundClass,
}) => {
  const pathname = location ? location.pathname.replace(/^\//, '').replace(/\/$/, '') : '/';
  return (
    <>
      <div className='flex flex-col h-full'>
        <Navbar pathname={pathname}
          navBackgroundClass={navBackgroundClass}
          asideColorScheme={asideColorScheme} />
        <main className='flex-1'>{children}</main>
        <Footer footerBackgroundClass={footerBackgroundClass} />
      </div>
    </>
  )
}

export default Layout;