import clsx from 'clsx';
import * as React from 'react';
import Link from './link';
import Markdown from 'react-markdown'

const HeadingBlock = ({
  headingType,
  colorAccent,
  value,
  url
}) => {
  const colorClass = () => {
    switch (colorAccent) {
      case 'one':
        return 'text-[color:var(--color-scheme-accent-one-bg)]';
      case 'two':
        return 'text-[color:var(--color-scheme-accent-two-bg)]';
      default:
        return 'text-[color:var(--color-scheme-foreground)]';
    }
  }
  const HeadingTag = ({children, ...props}) => {
    switch (headingType) {
      case 'h1':
        return <h1 className={clsx('w-full md:text-7xl text-6xl', colorClass())} {...props}>{children}</h1>
      case 'h2':
        return <h2 className={clsx('w-full md:text-6xl text-5xl', colorClass())} {...props}>{children}</h2>
      case 'h3':
        return <h3 className={clsx('w-full md:text-5xl text-4xl', colorClass())} {...props}>{children}</h3>
      case 'h4':
        return <h4 className={clsx('w-full md:text-4xl text-3xl', colorClass())} {...props}>{children}</h4>
      case 'h5':
        return <h5 className={clsx('w-full md:text-2xl text-xl', colorClass())} {...props}>{children}</h5>
      case 'h6':
        return <h6 className={clsx('w-full md:text-xl text-base', colorClass())} {...props}>{children}</h6>
      default:
        return <span className={clsx('w-full', colorClass())} {...props}>{children}</span>
    }
  }

  const Heading = ({children}) => <HeadingTag>{children}</HeadingTag>;
  const HeadingMarkdown = () => <Markdown>{value}</Markdown>

  return (
    <Heading>
      {url ? <Link to={url}
        className="no-underline"
        target="_blank"
        rel="nofollow"><HeadingMarkdown /></Link>
        : <HeadingMarkdown />}
    </Heading>
  )
}

export default HeadingBlock;