import * as React from 'react';
import FacebookIcon from './socialIcons/facebook';
import InstagramIcon from './socialIcons/instagram';
import PinterestIcon from './socialIcons/pinterest';
import SnapchatIcon from './socialIcons/snapchat';
import ThreadsIcon from './socialIcons/threads';
import TiktokIcon from './socialIcons/tiktok';
import TwitterIcon from './socialIcons/twitter';
import VimeoIcon from './socialIcons/vimeo';
import YoutubeIcon from './socialIcons/youtube';
import GithubIcon from './socialIcons/github';
import LinkedInIcon from './socialIcons/linkedin';
import Link from './link';

const SocialLinksList = ({
  links = [],
  iconClass = 'h-6 w-6 fill-[color:var(--color-scheme-icon)] hover:fill-[color:var(--color-scheme-icon-active)]',
  linkClass
}) => {
  const SocialIcon = ({
    type
  }) => {
    switch (type) {
      case 'facebook':
        return  <FacebookIcon className={iconClass} />
      case 'github':
        return <GithubIcon className={iconClass} />
      case 'instagram':
        return <InstagramIcon className={iconClass} />
      case 'linkedin':
        return <LinkedInIcon className={iconClass} />
      case 'pinterest':
        return <PinterestIcon className={iconClass} />
      case 'snapchat':
        return <SnapchatIcon className={iconClass} />
      case 'threads':
        return <ThreadsIcon className={iconClass} />
      case 'tiktok':
        return <TiktokIcon className={iconClass} />
      case 'twitter':
        return <TwitterIcon className={iconClass} />
      case 'vimeo':
        return <VimeoIcon className={iconClass} />
      case 'youtube':
        return <YoutubeIcon className={iconClass} />
      default:
        return <></>
    }
  }

  return (
    <>
      {links.map((i,k) => (
        <Link noFollow={true}
          to={i.url}
          key={k}
          className={linkClass}>
          <SocialIcon type={i.type} />
        </Link>
      ))}
    </>
  )
}

export default SocialLinksList;