import { PortableText } from '@portabletext/react';
import * as React from 'react';

const RichTextBlock = ({
  content
}) => {
  return (
    <div className='prose !max-w-none prose-h1:md:text-7xl prose-h1:text-6xl prose-h2:md:text-7xl prose-h2:text-5xl prose-h3:md:text-5xl prose-h3:text-4xl prose-h4:md:text-4xl prose-h4:text-3xl prose-h5:md:text-2xl prose-h5:text-xl prose-h6:md:text-xl prose-h6:text-base'>
      <PortableText value={content} />
    </div>
  )
}

export default RichTextBlock;