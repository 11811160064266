import * as React from 'react';

const InstagramIcon = ({
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.4 20.4"
      className={className}
      aria-hidden="true"
      fill="inherit">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1"><path d="M14.1,20.4H6.3A6.27,6.27,0,0,1,0,14.1V6.3A6.27,6.27,0,0,1,6.3,0h7.8a6.27,6.27,0,0,1,6.3,6.3v7.8A6.27,6.27,0,0,1,14.1,20.4ZM6.3,2.3a4,4,0,0,0-4,4v7.8a4,4,0,0,0,4,4h7.8a4,4,0,0,0,4-4V6.3a4,4,0,0,0-4-4Z" /><path d="M10.2,15.4a5.2,5.2,0,1,1,5.2-5.2A5.16,5.16,0,0,1,10.2,15.4Zm0-8.3a3.2,3.2,0,1,0,3.2,3.2A3.16,3.16,0,0,0,10.2,7.1Z" /><circle
            cx="15.7"
            cy="4.7"
            r="0.9" /></g>
      </g>
    </svg>
  )
}

export default InstagramIcon;