import * as React from 'react';

const PreviewLayout = ({children}) => {
  return (
    <>
      {children}
    </>
  )
}

export default PreviewLayout;