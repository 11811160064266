import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";

const Image = ({
  src,
  alt,
  className,
  style,
  ...props
}) => {
  if(src === null || src === undefined) {
    return <></>
  }
  const isGatsbyImage = (src) => {
    return ((src.childImageSharp !== null && src.childImageSharp !== undefined) || (src.gatsbyImage !== null && src.gatsbyImage !== undefined) || (src.gatsbyImageData !== null && src.gatsbyImageData !== undefined))  && src.extension !== 'svg';
  }
  return (
    <>
    {isGatsbyImage(src)
      ? <GatsbyImage image={getImage(src.extension === 'gif' ? src.gatsbyImageData : src.gatsbyImage)}
        alt={alt || 'Image'}
        className={className}
        style={style}
        {...props} />
      : <img src={src.publicURL}
          alt={alt}
          style={style}
          className={className} />}
    </>
  )
}

export default Image;