import * as React from 'react';

const PreviewBanner = () => {
  return (
    <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5"
      id="sanityPreviewBanner">
      <p className="text-sm leading-6 text-white flex-1 text-center">
        <a href=""
          target="_blank">
          <strong className="font-semibold">This is a preview.</strong>&nbsp;
          Click here to view in browser&nbsp;<span aria-hidden="true">&rarr;</span>
        </a>
      </p>
    </div>
  )
}

export default PreviewBanner;