import * as React from 'react';

const TiktokIcon = ({
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.31 20.34"
      className={className}
      aria-hidden="true"
      fill="inherit"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1"><path d="M12.51,0h0c0,.3.1,4.8,4.8,5.1V8.6a8.46,8.46,0,0,1-4.8-1.7v6.7c0,3-1.7,6-4.8,6.6a6.2,6.2,0,0,1-3-.1c-7.8-2.3-5.2-13.8,2.6-12.6v3.7c-3.2-.5-4.3,2.2-3.4,4.1A2.89,2.89,0,0,0,9,15a7.77,7.77,0,0,0,.2-1.6V0Z" /></g>
      </g>
    </svg>
  )
}

export default TiktokIcon;