import { Disclosure, Transition } from '@headlessui/react';
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import Link from './link';
import Image from './Image';
import { globalHistory } from '@reach/router'

const Navbar = ({
  pathname,
  navBackgroundClass = "color-scheme-one",
  asideColorScheme = "color-scheme-two"
}) => {
  const isHomepage = !pathname.length;
  const headerSpotRef = React.useRef(null);
  const headerRef = React.useRef(null);
  const [isFixed, setIsFixed] = React.useState(false);
  const [resetHeader, setResetHeader] = React.useState(false);

  const navSettings = useStaticQuery(graphql`
    query {
      sanityNavigation {
        navigationLogos {
          image {
            src: asset {
              gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 600)
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 600)
              publicURL: url
              extension
            }
            alt: caption
          }
          customWidth
          width
          colorScheme
        }
        links {
          link {
            url
            label
            newWindow
            noFollow
          }
          image {
            src: asset {
              gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 600)
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 600)
              publicURL: url
              extension
            }
            alt: caption
          }
        }
      }
    }
  `)

  const {
    navigationLogos = [],
    links = []
  } = navSettings?.sanityNavigation || {}

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setResetHeader(true);
        setTimeout(() => {
          setResetHeader(false);
        }, 100)
      }
    });
  }, []);

  const HeaderLogo = ({
    colorReference = navBackgroundClass,
    className = 'max-w-[120px] lg:max-w-[140px]'
  }) => {
    const filterLogos = navigationLogos.filter(i => i.colorScheme === colorReference);
    const logoSettings = filterLogos.length > 0 ? filterLogos[0] : navigationLogos[0];
    const {
      customWidth,
      width,
      image
    } = logoSettings;
    const props = {
      style: {},
      className: [className]
    };
    if(width === 'full') {
      props.layout = 'fullWidth';
      props.className.push('w-full');
    }
    else if(width === 'custom') {
      props.width = customWidth;
      props.style.width = `${customWidth}px`;
    }
    return <Image src={image.src}
      alt={image.alt}
      className={clsx(props.className)}
      {...props} />
  }

  React.useEffect(() => {
    const target = headerSpotRef.current;
    const observer = new IntersectionObserver((entries) => {
      if(isHomepage) {
        const isVisible = entries[0].isIntersecting;
        if(isVisible) {
          setIsFixed(false);
          target.style.removeProperty('--custom-height');
        }
        else {
          setIsFixed(true);
          const customHeight = headerRef.current?.offsetHeight;
          target.style.setProperty('--custom-height', `${customHeight}px`);
        }
      }
    }, {
      root: null,
      rootMargin: "0px",
      threshold: 0
    })

    if(target) {
      observer.observe(target);
    }

    return () => {
      if(target) {
        observer.unobserve(target);
      }
    }
  }, []);

  return (
    <>
      {!resetHeader && <Disclosure as="nav" className={clsx("bg-[var(--color-scheme-background)] text-[color:var(--color-scheme-foreground)] z-10", (isFixed) && "fixed w-full", navBackgroundClass, (!isHomepage || isFixed) && 'top-0')}>
        {({open}) => (
          <>
            <div className='relative contain' ref={headerRef}>
              <div className='relative flex h-28 justify-between'>
                <div className='flex flex-1 items-center md:justify-center'>
                  <Link to="/" className="flex flex-shrink-0 items-center h-12 md:h-16">
                    <HeaderLogo />
                  </Link>
                </div>
                <div className='absolute inset-y-0 right-0 flex items-center'>
                  <Disclosure.Button className="py-4 items-center pointer-events-auto">
                    <span className="sr-only">Open main menu</span>
                    <div className={clsx("before:bg-[var(--color-scheme-icon)] after:bg-[var(--color-scheme-icon)] w-[30px] h-[2px] md:w-[40px] md:h-[3px] relative m-0 md:mt-2 before:content-[''] before:w-[30px] before:md:w-[40px] before:h-[2px] before:md:h-[3px] before:rounded-sm before:absolute before:right-0 before:transition-transform before:duration-300 before:ease-in-out after:rounded-sm after:content-[''] after:h-[2px] after:md:h-[3px]  after:absolute after:right-0 after:transition-transform after:duration-300 after:ease-in-out", open ? "before:rotate-45 before:top-0 after:-rotate-45 after:top-0 after:w-[30px] after:md:w-[40px]" : "before:-top-1 after:top-1 after:w-[24px] after:md:w-[30px]")}></div>
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Transition className='fixed left-0 top-0 h-full w-full'
              enter="duration-700 transition origin-top-left opacity-0 z-10 scale-0"
              enterFrom="scale-0 origin-top-left opacity-0"
              enterTo="scale-100 origin-bottom-right opacity-100"
              leave="duration-700 transition fixed left-0 top-0 h-full w-full origin-bottom-right opacity-0 z-10"
              leaveFrom="scale-100 origin-bottom-right opacity-100"
              leaveTo="scale-0 origin-top-left opacity-0">
                <Disclosure.Panel className={clsx('fixed overflow-auto left-0 top-0 h-full w-full bg-[var(--color-scheme-background)] z-10', asideColorScheme)}>
                  <div className='contain relative'>
                    <div className="relative flex h-28 justify-between">
                      <div className="absolute inset-y-0 right-0 flex items-center">
                        <Disclosure.Button className="py-4 items-center pointer-events-auto">
                          <span className="sr-only">Close main menu</span>
                          <div className="before:bg-[var(--color-scheme-icon)] after:bg-[var(--color-scheme-icon)] w-[30px] h-[2px] md:w-[40px] md:h-[3px] relative m-0 md:mt-2 before:content-[''] before:w-[30px] before:md:w-[40px] before:h-[2px] before:md:h-[3px] before:rounded-sm before:absolute before:right-0 before:transition-transform before:duration-300 before:ease-in-out after:rounded-sm after:content-[''] after:h-[2px] after:md:h-[3px]  after:absolute after:right-0 after:transition-transform after:duration-300 after:ease-in-out before:rotate-45 before:top-0 after:-rotate-45 after:top-0 after:w-[30px] after:md:w-[40px]"></div>
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>
                  <div className="contain w-full pt-6 pb-12 md:py-24">
                    <h5 className='text-lg font-bold text-center mb-8 text-[color:var(--color-scheme-foreground)]'>Menu</h5>
                    <div className='flex flex-col items-center space-y-16 text-xl'>
                      {links.map((i,k) => (
                        <Link key={k}
                          to={i.link.url}
                          {...i.link}
                          className="font-heading text-4xl sm:text-6xl text-center group">
                            {i.image ? <>
                              <Image {...i.image}
                                className="w-full max-w-[200px] md:max-w-[400px] hover:scale-110 transition-transform" />
                            </>
                            : <>
                              <div className="text-[color:var(--color-scheme-foreground)]">{i.link.label}</div>
                              <div className='bg-[var(--color-scheme-background)] group-hover:bg-[var(--color-scheme-border)] w-full h-[2px] transition-all duration-300'></div>
                            </>}
                          </Link>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>}

      <div className='h-[var(--custom-height)]' ref={headerSpotRef}></div>
    </>
  )
}

export default Navbar;